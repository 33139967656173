@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Inter", sans-serif !important;
}

.glitch {
    position: relative;
    /* font-size: 80px; */
    /* font-weight: 700; */
    /* line-height: 1.2; */
    color: #fff;
    /* letter-spacing: 5px; */
    z-index: 1;
}

.glitch:before,
.glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
}

.glitch:before {
    animation: glitch-color 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.01) both infinite;
    color: #065ee1;
    z-index: -1;
}

.glitch:after {
    animation: glitch-color 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.01) reverse both infinite;
    color: #e42822;
    z-index: -2;
}

@keyframes glitch-color {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-3px, 3px);
    }

    40% {
        transform: translate(-3px, -3px);
    }

    60% {
        transform: translate(3px, 3px);
    }

    80% {
        transform: translate(3px, -3px);
    }

    to {
        transform: translate(0);
    }
}

/* Glitch with Noise */
.glitch-noise {
    position: relative;
    /* font-size: 80px; */
    /* font-weight: 700; */
    /* line-height: 1.2; */
    /* color: #fff; */
    /* letter-spacing: 5px; */
    z-index: 1;
}

.glitch-noise:before {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    color: #fff;
    background-color: #222;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-before 3s infinite linear alternate-reverse;
}

.glitch-noise:after {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    color: #fff;
    background-color: #222;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-after 2s infinite linear alternate-reverse;
}

@keyframes noise-before {
    0% {
        clip: rect(61px, 9999px, 52px, 0);
    }

    5% {
        clip: rect(33px, 9999px, 144px, 0);
    }

    10% {
        clip: rect(121px, 9999px, 115px, 0);
    }

    15% {
        clip: rect(144px, 9999px, 162px, 0);
    }

    20% {
        clip: rect(62px, 9999px, 180px, 0);
    }

    25% {
        clip: rect(34px, 9999px, 42px, 0);
    }

    30% {
        clip: rect(147px, 9999px, 179px, 0);
    }

    35% {
        clip: rect(99px, 9999px, 63px, 0);
    }

    40% {
        clip: rect(188px, 9999px, 122px, 0);
    }

    45% {
        clip: rect(154px, 9999px, 14px, 0);
    }

    50% {
        clip: rect(63px, 9999px, 37px, 0);
    }

    55% {
        clip: rect(161px, 9999px, 147px, 0);
    }

    60% {
        clip: rect(109px, 9999px, 175px, 0);
    }

    65% {
        clip: rect(157px, 9999px, 88px, 0);
    }

    70% {
        clip: rect(173px, 9999px, 131px, 0);
    }

    75% {
        clip: rect(62px, 9999px, 70px, 0);
    }

    80% {
        clip: rect(24px, 9999px, 153px, 0);
    }

    85% {
        clip: rect(138px, 9999px, 40px, 0);
    }

    90% {
        clip: rect(79px, 9999px, 136px, 0);
    }

    95% {
        clip: rect(25px, 9999px, 34px, 0);
    }

    100% {
        clip: rect(173px, 9999px, 166px, 0);
    }
}

@keyframes noise-after {
    0% {
        clip: rect(26px, 9999px, 33px, 0);
    }

    5% {
        clip: rect(140px, 9999px, 198px, 0);
    }

    10% {
        clip: rect(184px, 9999px, 89px, 0);
    }

    15% {
        clip: rect(121px, 9999px, 6px, 0);
    }

    20% {
        clip: rect(181px, 9999px, 99px, 0);
    }

    25% {
        clip: rect(154px, 9999px, 133px, 0);
    }

    30% {
        clip: rect(134px, 9999px, 169px, 0);
    }

    35% {
        clip: rect(26px, 9999px, 187px, 0);
    }

    40% {
        clip: rect(147px, 9999px, 137px, 0);
    }

    45% {
        clip: rect(31px, 9999px, 52px, 0);
    }

    50% {
        clip: rect(191px, 9999px, 109px, 0);
    }

    55% {
        clip: rect(74px, 9999px, 54px, 0);
    }

    60% {
        clip: rect(145px, 9999px, 75px, 0);
    }

    65% {
        clip: rect(153px, 9999px, 198px, 0);
    }

    70% {
        clip: rect(99px, 9999px, 136px, 0);
    }

    75% {
        clip: rect(118px, 9999px, 192px, 0);
    }

    80% {
        clip: rect(1px, 9999px, 83px, 0);
    }

    85% {
        clip: rect(145px, 9999px, 98px, 0);
    }

    90% {
        clip: rect(121px, 9999px, 154px, 0);
    }

    95% {
        clip: rect(156px, 9999px, 44px, 0);
    }

    100% {
        clip: rect(67px, 9999px, 122px, 0);
    }
}

/* Glitch with Transformation */

.glitch-trans {
    position: relative;
    /* font-size: 80px; */
    /* font-weight: 700; */
    /* line-height: 1.2; */
    /* color: #fff; */
    /* letter-spacing: 5px; */
    animation: shift 4s ease-in-out infinite alternate;
    transform: skewX(0deg);
    /* z-index: 1; */
}

@keyframes shift {

    0%,
    40%,
    44%,
    58%,
    61%,
    65%,
    69%,
    73%,
    100% {
        transform: skewX(0deg);
    }

    41% {
        transform: skewX(10deg);
    }

    42% {
        transform: skewX(-10deg);
    }

    59% {
        transform: skewX(40deg) skewY(10deg);
    }

    60% {
        transform: skewX(-40deg) skewY(-10deg);
    }

    63% {
        transform: skewX(10deg) skewY(-5deg);
    }

    70% {
        transform: skewX(-50deg) skewY(-20deg);
    }

    71% {
        transform: skewX(10deg) skewY(-10deg);
    }
}

.modal-content {
    overflow: auto;
    max-height: calc(100vh - 120px);
    max-width: calc(100vw - 60px);
}

.modal-content video {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    border-radius: 0 0 0.75rem 0.75rem;
}

/* custom scroll bar */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #000;
    /* Dark background for the track */
}

*::-webkit-scrollbar-thumb {
    background-color: #ef4444;
    /* Red color for the thumb */
    border-radius: 10px;
    border: 2px solid #000;
    /* Dark border for better visibility */
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #707070;
}

* {
    scrollbar-color: #fff #000;
    /* Thumb and track colors */
    scrollbar-width: thin;
    /* Narrow scroll bar */
}


.success-slider {
    padding-top: 50px !important;
    /* background-color: red; */
}

.react-multiple-carousel__arrow {
    border: 1px solid red !important;
    background-color: white !important;
    border-radius: 50% !important;
    /* Full rounding for circular shape */
    padding: 5px !important;
    transition: background-color 0.5s ease-in-out !important;
    /* Smooth transition */
}

.react-multiple-carousel__arrow::before {
    color: #000 !important;
}

.react-multiple-carousel__arrow:hover {
    background-color: red !important;
}

body {
    background-color: #000;
}

html {
    scroll-behavior: smooth;
    overscroll-behavior: contain;
}

#root {
    overflow: hidden !important;
}

.dots-nav::after {
    left: calc(50% - 0.325rem);
}

@media (max-width:768px) {
    .modal-video video {
        margin-bottom: 20px;
    }
}

@media (max-width:600px) {

    .media-coverage img,
    .clients img {
        max-width: 100%;
    }
}

.password-icon-btn {
    width: 50px;
    height: 66px;
    bottom: 0;
    position: absolute;
    top: auto;
}

.password-icon-btn svg {
    height: calc(100% - 10px) !important;
    max-height: calc(100% - 10px) !important;
    width: 25px !important;
}

.notistack-SnackbarContainer {
    z-index: 3000 !important;
}

.delete svg path {
    width: 100% !important;
}

.add-media button {
    right: 0 !important
}

.background {
    /* background: linear-gradient(180deg, #000000 0%, #0a1e3a 50%, #000000 100%); */
    background: linear-gradient(180deg, #1a2a4e 0%, #2c3e70 50%, #1a2a4e 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

.radial {
    background: radial-gradient(700px 100%, rgba(255, 51, 85, 0) 0%, rgb(0, 0, 0) 150%)
}

.edit-icon-btn svg {
    width: 60%;
    height: 60%;
    fill: #ef4444;
    display: flex;
    justify-content: center;
    text-align: center;
}