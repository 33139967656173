/* Add this to your global CSS file or a dedicated CSS file for the loading screen */
.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    /* Dark background */
    color: #fff;
    /* Light text color */
    z-index: 999999;
    /* Ensure it's above other elements */
}





.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-left-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.sm-loading{
    width: 25px;
    height: 25px;
}
/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Slow spin for the logo */
@keyframes slowspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
